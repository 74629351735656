.parameter-card-2 {
  padding: 2rem 5rem;
  background: $color-grey-light-2;
  &__name {
    font-size: 1.6rem;
  }
  &__value {
    font-size: 4rem;
  }
  &__standard {
    font-size: 1.6rem;
  }
  &__range {
    font-size: 1.6rem;
  }
}
