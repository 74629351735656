.leaflet-map {
  margin-top: 1rem;
  height: 90%;
  border: $line;
  @include respond("mobile") {
    height: 40rem;
  }
  position: relative;

  &__legend {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: $white;
    opacity: 0.75;
    display: flex;
  }
}

.legend-item {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__icon {
    width: 3rem;
    height: 3rem;
  }
  &__text {
  }
}
