.accordion-container {
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.accordion {
  width: 100%;
  overflow: hidden;
  padding: 0.5rem;
  &__label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $color-grey-light-1;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($color-grey-light-1, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &__content {
    max-height: 0;
    padding: 0 1em;
    color: $text-color-dark;
    background: white;
    transition: all 0.35s;
    overflow: hidden;
  }
  &__close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $color-grey-light-1;
    cursor: pointer;
    &:hover {
      background: darken($color-grey-light-1, 10%);
    }
  }

  & input.accordion__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  // :checked
  & input.accordion__input:checked {
    + .accordion__label {
      background: darken($color-grey-light-1, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .accordion__content {
      max-height: 1000vh;
      padding: 1em;
    }
  }
}
