.page-header {
  &__main {
    padding: 0 0.5rem;
    font-size: 2rem;
    display: inline-block;
    text-transform: capitalize;
  }
  &__description {
    margin-left: 1rem;
    text-transform: lowercase;
  }
}
