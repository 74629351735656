.live-feed {
  min-height: 200px;
  &__options {
    display: flex;
    justify-content: center;
  }
  &__view {
    position: relative;
    &__header {
      background-color: white;
      opacity: 0.8;
      @include horizontalCenter;
      top: 10%;
      padding: 1rem;
    }
    &__content {
      width: 100%;
      height: 500px;
    }
  }
}
