// .login-bg::after {
//   background-color: #fef5e4;
//   -webkit-border-radius: 0 0 233px 135px;
//   -moz-border-radius: 0 0 233px 135px;
//   border-radius: 0 0 233px 135px;
//   content: "";
//   position: absolute;
//   right: -119px;
//   top: -93px;
//   background-color: #fef5e4;
//   width: 466px;
//   height: 595px;
//   -webkit-transform: rotate(45deg);
//   transform: rotate(116deg);
//   z-index: -2;
// }
.login__image {
  display: flex;
  margin-left: 40px;
  height: 350px;
  width: 350px;
}
.login-container {
  contain: content;
  margin: 40px auto;
  height: 540px;
  width: 85%;
  background-color: #ff5f52;
  border-radius: 0 70px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.login-container::before {
  content: "";
  background: url(https://1.bp.blogspot.com/--UQ8_O1EFN8/XWkZdpW_MMI/AAAAAAAATDE/kcJXA9nMy4ElB4NmBZDM6WwPE4JQD7ACQCLcBGAs/s1600/shape.png);
  position: absolute;
  right: -20px;
  background-size: 100%;
  top: -80px;
  width: 122rem;
  height: 65.3rem;
}

.login-link {
  -webkit-animation: wait 0s alternate infinite;
  animation: wait 0s alternate infinite;
}

.circle-ripple {
  background-color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
  left: 60px;
  position: absolute;
  top: 44px;
}

@-webkit-keyframes wait {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wait {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(175, 166, 255, 0.3),
      0 0 0 1em rgba(175, 166, 255, 0.3), 0 0 0 3em rgba(175, 166, 255, 0.3),
      0 0 0 5em rgba(175, 166, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(175, 166, 255, 0.3),
      0 0 0 3em rgba(175, 166, 255, 0.3), 0 0 0 5em rgba(175, 166, 255, 0.3),
      0 0 0 8em rgba(175, 166, 255, 0);
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 #fc6d6d, 0 0 0 1em #fc6d6d, 0 0 0 3em #fc6d6d,
      0 0 0 5em #fc6d6d;
  }
  100% {
    box-shadow: 0 0 0 1em #ffbaba, 0 0 0 3em #fc6d6d, 0 0 0 5em #f10f0f,
      0 0 0 8em #ca1212;
  }
}

@media only screen and (max-width: 728px) {
  #a {
    display: none;
  }
  .dis-none {
    display: none;
  }

  .login-container {
    background-color: white;
    box-shadow: none;
  }
}

@media only screen and (max-width: 1070px) {
  .login-container::before {
    background-size: 48%;
    top: -80px;
    background-repeat: no-repeat;
    background: none;
  }

  .wrap-login100 {
    width: 90%;
  }
}

/* login form */

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 75vh;; */
  margin: 50px auto;
}

.screen {
  background: linear-gradient(90deg, #e46b6b, #ff5f52);
  position: relative;
  height: 430px;
  width: 360px;
  box-shadow: 0px 0px 24px #cb6565;
  border-radius: 20px;
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #fff;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #c63434;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #d65454, #c84040);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #be7e7e;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.login {
  width: 320px;
  padding: 50px 20px;
}

.login__field {
  padding: 0.5rem 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 25px;
  color: #c84040;
}

.login__input {
  border: none;
  border-bottom: 2px solid rgb(223, 92, 92);
  background: none;
  padding: 10px;
  font-size: 16px;
  padding-left: 34px;
  font-weight: 700;
  width: 75%;
  transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #c84040;
}

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid rgb(241, 59, 56);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  /* width: 100%; */
  color: #c84040;
  box-shadow: 0px 2px 2px #c84040;
  cursor: pointer;
  transition: 0.2s;
}
.login__biosphere {
  display: flex;
  margin-left: 25px;
  border-radius: 26px;
  border: 1px solid rgb(241, 59, 56);
  color: #c84040;
  box-shadow: 0px 2px 2px #c84040;
}
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #c84040;
  outline: none;
}

.login-bg .button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #c84040;
}
.heading-left {
  position: relative;
  color: white;
  width: 65%;
  text-align: center;
  text-transform: uppercase;
  line-height: 25px;
  margin: 20px auto;
  letter-spacing: 3px;
}
.login__mobile_image{
  display: none;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  object-fit: cover;
  transform: translate(-50%,-50%);
  z-index: -1;
  height: 100%;
  
}
.title__login{
  display: none;
}
.copyright__section__mobile{
  display: none;
}
.svg-back{
background-attachment: fixed;
background-size: cover;
object-fit: cover;
position: absolute;
width: 100%;
height: 100%;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
}
.reset_pass_btn{
  color: aliceblue;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #c63434;
}
@media only screen and (max-width: 728px) {
 
 
  .video__login{
    display: none;
    }
    .title__login{
      display: block;
    }
    .login__mobile_image{
      display: block;
      overflow: hidden;
    }
    .copyright__section{
      display: none;
    }
    .login-container {
      contain: layout;
      height: 0px;
      margin: 10px auto;
    }
    .container-login{
      margin: 30px auto;
    }
    .copyright__section__mobile{
      display: block;
    }
}
