.custom-table .ag-header-group-cell {
  color: hsl(207, 48%, 17%);
}

.custom-table .ag-header-cell {
  background-color: hsl(0, 73%, 71%);
  color: black
}

// .custom-table .ag-header-cell:hover {
//   // background-color: black; /* Change the background color on hover */
//   color: black; /* You can also change the text color on hover */
// }

.custom-table .ag-root-wrapper {
  border-radius: 1.1rem;
  overflow: hidden;
}

.custom-table .ag-theme-balham {
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-balham-active-color: black;

}