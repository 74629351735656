$text-color: $text-color-dark;
@import "react-widgets/styles.css";
@import 'react-multi-email/dist/style.css';

// .rw-btn {
//   color: #000;
// }

// .rw-list-option {
//   color: #000;

//   &.rw-state-focus {
//     color: #000;
//   }
// }