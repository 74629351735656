.feature {
  // &__box {

  // }
  // &__help {

  // }
  // &--active &__box {

  // }
  &--enabled &__help {
    display: none;
  }
  &--disabled &__box {
    display: none;
  }
  // &--disabled &__help {
  // }
}
