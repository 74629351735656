$color-primary: #c62828;
$color-primary-light: #e64031;
$color-primary-dark: #8e0000;
$color-secondary: #1565c0;
$color-secondary-light: #5e92f3;
$color-secondary-dark: #003c8f;
$text-color: #fff;
$text-color-dark: #333;
$color-grey-light-1: #faf9f9;
$color-grey-light-2: #f4f2f2;
$color-grey-light-3: #f0eeee;
$color-grey-light-4: #ccc;
$color-grey-dark-1: #333;
$color-grey-dark-2: #777;
$color-grey-dark-3: #999;
///// Loader Variables
$loader-color: $color-grey-dark-1;
$loader-ball-size: 1.5rem !default;
$loader-margin: 2px !default;
////// line
$line: 1px solid $color-grey-light-4;
////// Screen sizes
$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
///////////////// Bulma Variables
$primary: $color-primary-light;
$size-1: 4.8rem !default;
$size-2: 4rem !default;
$size-3: 3.2rem !default;
$size-4: 2.4rem !default;
$size-5: 2rem !default;
$size-6: 1.6rem !default;
$size-7: 1.2rem !default;
$block-spacing: 2.4rem !default;
//// Bulma variable for tabs
$tabs-link-active-border-bottom-color: $color-primary;
$tabs-link-active-color: $color-primary;
// nav bar bulma variables
$navbar-height: 5.2rem;
$navbar-padding-horizontal: 3.2rem;
$navbar-padding-vertical: 1.6rem;
// side bar bulma variables
$menu-item-active-background-color: $color-primary;
$menu-item-hover-background-color: $color-primary-light;
$menu-label-color: $text-color;
$menu-item-color: $text-color;
$menu-list-link-padding: 0.8rem 1.2rem;
$menu-label-font-size: 1.2rem;
$menu-nested-list-margin: 1.2rem;
$menu-nested-list-padding-left: 1.2rem;
$menu-label-spacing: 1.6rem;
$menu-label-letter-spacing: 0.2rem;
// Used in login.jsx
$bckgCol: white;
