.logo {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: $color-primary-light;
  color: $color-grey-light-1;
  &__icon {
    margin-left: 2rem;
    width: 3.5rem;
    // height: 2.5rem;
    fill: currentColor;
  }

  &__text {
    margin-left: 2rem;
    text-transform: uppercase;
  }
}
