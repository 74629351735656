.is-clickable {
  cursor: pointer;
}

.is-current-color {
  fill: currentColor;
}

.is-green {
  background-color: #228b22;
}

.is-yellow {
  background-color: #e7b416;
}

.is-blue {
  background-color: #00f;
}

.is-red {
  background-color: #f00;
}

.is-maroon {
  background-color: lighten(#d24040, 5%);
}
.is-light-green {
  background-color: #829f42;
}
.is-yellow-light {
  background-color: #e4ca49;
}

.is-primary {
  background-color: $color-primary;
}

.is-secondary {
  background-color: $color-secondary;
}

.is-text-primary {
  color: lighten(#e74c3c, 5%);
}

.is-pointer {
  cursor: pointer;
}

.is-text-light {
  color: #eee;
}

.util-icon {
  &--small {
    width: 0.5rem;
    height: 0.5rem;
  }

  &--medium {
    width: 1rem;
    height: 1rem;
  }

  &--large {
    width: 2rem;
    height: 2rem;
  }
}

.fill-green {
  fill: #228b22;
}

.fill-yellow {
  fill: #e7b416;
}

.fill-red {
  fill: #ff0000;
}

.fill-black {
  fill: #000;
}

.fill-white {
  fill: #f2f2f2;
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.is-fixed {
  position: fixed;
}

.min-height {
  &--1 {
    min-height: 20rem;
  }

  &--2 {
    min-height: 40rem;
  }

  &--3 {
    min-height: 60rem;
  }

  &--4 {
    min-height: 80rem;
  }

  &--5 {
    min-height: 100rem;
  }
}

.elevated {
  &--1 {
    z-index: 10;
  }

  &--2 {
    z-index: 20;
  }

  &--3 {
    z-index: 30;
  }

  &--4 {
    z-index: 40;
  }

  &--5 {
    z-index: 50;
  }
}
.box-shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.tour_btn{
 cursor: pointer;
}