////// For Stats Card //////////////////////////
.stats-card {
  margin: 3rem 1rem 1rem 1rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.3rem;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;

  &__header {
    // background-color: #ff0;
    fill: currentColor;
    float: left;

    width: 8rem;
    height: 8rem;
    text-align: center;
    margin: -2rem 1.5rem 0;
    border-radius: 0.3rem;
    padding: 1.1rem;
  }
  &__content {
    background-color: $color-grey-light-1;
    text-align: right;
    padding: 1.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
  }
  &__title {
    background-color: $color-grey-light-1;
    text-align: right;
    font-size: 1.5rem;
    display: block;
    cursor: pointer;
  }
  &__value {
    background-color: $color-grey-light-1;
    text-align: right;
    font-size: 2.5rem;
    display: block;
    cursor: pointer;
  }

  &__footer {
    background-color: $color-grey-light-1;
    border-top: 1px solid #eee;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
    font-size: 2rem;
  }

  &__link {
    color: currentColor;

    &:hover {
      color: currentColor;
    }
  }
}
