.marquee {
  overflow: hidden;

  & span {
    display: inline-block;
    width: max-content;

    padding-left: 100%;
    /* show the marquee just outside the paragraph */
    will-change: transform;
    animation: marquee 20s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-weight: bold;
      font-size: 18px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@media only screen and (max-width: 600px) {
  .animate-charcter {
    font-size: 15px;
  }
}