.carouselv2-container {
  position: relative;
}

.carouselv2-direction {
  cursor: pointer;
  position: absolute;
  top: 60%;
  width: auto;
  margin-top: -30px;
  color: #888;
  font-weight: bold;
  font-size: 16px;
  border-radius: 0 3px 3px 0;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  &--next {
    position: absolute;
    right: 0px;
    border-radius: 3px 0 0 3px;
  }
}

.carouselv2-dot-container {
  margin: auto;
  text-align: center;
}

/* The dots/bullets/indicators */
.carouselv2-dot {
  cursor: pointer;
  height: 7px;
  width: 7px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;

  &.active {
    background-color: #d37269;
  }

  &:hover {
    background-color: #717171;
  }
}
.carouselv2-animated {
  position: relative;
  left: -100%; /* Start off-screen to the left */
  animation: slideAnimation 1s ease-out forwards;
}
/* Add animation styles */
@keyframes slideAnimation {
  to {
    left: 0;
  }
}
