.svgicon {
  &.is-24x24 {
    width: 2.4rem;
    height: 2.4rem;
    fill: currentColor;
    stroke-width: 0;
    stroke: currentColor;
  }
  &.is-48x48 {
    width: 4.8rem;
    height: 4.8rem;
    fill: currentColor;
    stroke-width: 0;
    stroke: currentColor;
  }

  &.is-72x72 {
    width: 7.2rem;
    height: 7.2rem;
    fill: currentColor;
    stroke-width: 0;
    stroke: currentColor;
  }

  &.is-96x96 {
    width: 9.6rem;
    height: 9.6rem;
    fill: currentColor;
    stroke-width: 0;
    stroke: currentColor;
  }

  &.is-margin-small {
    margin: 0.5rem;
  }
  &.is-margin-medium {
    margin: 1rem;
  }
  &.is-margin-large {
    margin: 2rem;
  }

  &.is-padding-small {
    padding: 0.5rem;
  }
  &.is-padding-medium {
    padding: 1rem;
  }
  &.is-padding-large {
    padding: 2rem;
  }
}
