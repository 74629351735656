.loader-container {
  // background-color: $color-grey-light-1;
  // position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 0;
  right: 0;
  width: 100%; /* Full width */
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 70rem;
    height: 15rem;

    @include respond("mobile") {
      width: 100vw;
      margin: 0 1rem;
    }

    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__icon {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
  }

  &__text {
    flex: 1 1 auto;
    font-size: 2.5rem;
  }
}

.loader {
  padding: 1rem 5rem;
  margin: auto;
}

$rise-amount: 30px;

@keyframes ball-pulse-rise-even {
  0% {
    transform: scale(1.1);
  }
  25% {
    transform: translateY(-$rise-amount);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: translateY($rise-amount);
  }
  100% {
    transform: translateY(0);
    transform: scale(1);
  }
}

@keyframes ball-pulse-rise-odd {
  0% {
    transform: scale(0.4);
  }
  25% {
    transform: translateY($rise-amount);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: translateY(-$rise-amount);
  }
  100% {
    transform: translateY(0);
    transform: scale(0.75);
  }
}

.ball-pulse-rise {
  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.15, 0.46, 0.9, 0.6);
    animation-iteration-count: infinite;
    animation-delay: 0;

    &:nth-child(2n) {
      animation-name: ball-pulse-rise-even;
    }

    &:nth-child(2n-1) {
      animation-name: ball-pulse-rise-odd;
    }
  }
}
