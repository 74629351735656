body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
}

.capitalize {
  text-transform: capitalize;
}
