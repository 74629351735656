.parameter-card {
  margin-top: 1.5em;
  position: relative;
  &__header {
    width: 100%;
    display: flex;
    margin: 1rem 1rem;

    &__icon {
      flex: 0;
      box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28),
        0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 188, 212, 0.2);
      border-radius: 3px;
      margin-top: -2rem;
      &.is-flow {
        background: linear-gradient(60deg, #26c6da, #00acc1);
      }
    }
    &__content {
      flex: 1;
      align-self: center;
      text-align: center;
    }
    &__heartbeat {
      width: 4rem;
      height: 4rem;
      margin-right: 2rem;
    }
  }
  &__enlarge-icon {
    cursor: pointer;
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__content {
    flex: 1;
  }
  &__footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.5rem;
  }
}

.reading {
  padding: 0 0.5rem;
  display: flex;
  &__name {
    flex: 1;
  }
  &__value {
    flex: 1;
  }
}
