.report-chart-modal {
  min-height: 50rem;
}

.report-chart-card {
  &__footer {
    padding: 1rem 2rem;
  }
}

.report-chart-container {
  padding: 1rem;
  background-color: $white;
}
