@import "sass/abstracts/variables";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/all";
@import "bulma/sass/elements/button";
@import "bulma/sass/form/all";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/title";
@import "bulma/sass/elements/icon";
@import "bulma/sass/elements/box";
@import "bulma/sass/elements/container";
@import "bulma/sass/components/tabs";
@import "bulma/sass/components/modal";
@import "bulma/sass/components/menu";
@import "bulma/sass/components/navbar";
@import "bulma/sass/components/breadcrumb";
@import "bulma/sass/components/card";
@import "bulma/sass/elements/tag";
@import "bulma/sass/components/media";
@import "bulma-carousel";


// @import "bulma-calendar/src/sass/index";
// @import "bulma-calendar/src/sass/timepicker";

.carousel--hidden {
  overflow: hidden;
  
}

.slider{
  overflow: hidden;
}

.slider-navigation-next{
  opacity: 0.1;
  &:hover{
    opacity: 2;
  }
  
}
// .slider-pagination{
  
// }
.slider-pagination .slider-page{
  opacity: 0.7;
}

.slider-navigation-previous
{
  opacity: 0.1;
  &:hover{
    opacity: 2;
  }
}

.card-full-height {
  height: 100%;
}

.select-full-width {
  width: 100%;

  select {
    width: 100%;
  }
}

.tabs ul {
  @include respond("mobile") {
    flex-shrink: 1;
    flex-wrap: wrap;
    border-bottom-color: transparent;
  }
}

.menu-list .open {
  display: block;
}
.menu-list .close {
  display: none;
}

.modal-content {
  // overflow: visible;
  &--small-width {
    @include respond("mobile") {
      width: calc(100vw - 4rem);
    }
    width: 64rem;
  }
  &--medium-width {
    @include respond("mobile") {
      width: calc(100vw - 4rem);
    }
    width: 75rem;
  }
  &--full-width {
    width: calc(100vw - 4rem);
  }

  &--full-height {
    height: calc(100vh - 4rem);
  }
}

.card {
  overflow: visible;
}


