@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin horizontalCenter {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin global-bg() {
  background-color: $loader-color;
}

@mixin global-animation() {
  animation-fill-mode: both;
}

@mixin balls() {
  @include global-bg();

  width: $loader-ball-size;
  height: $loader-ball-size;
  border-radius: 100%;
  margin: $loader-margin;
}

// Media query mananger

// 0-600px Phone
// 600-900px Tablet portrait
// 900-1200px Tablet landscape
// 1200-1800px Normal CSS
// 1800px Big desktop

@mixin respond($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: 768px) {
      @content;
    } //600px
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 1023px) {
      @content;
    } //900px
  }
  @if $breakpoint == desktop {
    @media only screen and (max-width: 1215px) {
      @content;
    } //1200px
  }
  @if $breakpoint == widescreen {
    @media only screen and (max-width: 1407px) {
      @content;
    } //1200px
  }
  @if $breakpoint == full-hd {
    @media only screen and (min-width: 1408px) {
      @content;
    } //1800
  }
}
