/* .image__section_card {
  max-width: 100%;
  display: block;
} */

/* Utilities */
.user_details_card::after,
.user_details_card {
  border-radius: 50%;
}
.user_details_card {
  display: flex;
}

.user_details_card {
  padding: 1.5rem 1rem;
  border-radius: 10px;
  background-color: #ec7062;
  min-width: 339px;
  // max-width: 409px;
  height: 186px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.user_details_card::before,
.user_details_card::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.user_details_card::before {
  width: 100%;
  height: 100%;
  border: 1px solid #f9f7f7;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}
.user_details_card::after {
  height: 15rem;
  width: 15rem;
  background-color: #c81d1d;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #fff;
}

.user__details_section {
  margin-left: 1.5rem;
}

.card__text__section {
  margin-bottom: 0.4rem;
}
.card__text__section h2 {
  font-size: 1.3rem;
}
.card__text__section h4 {
  margin-bottom: 0.5rem;

  font-size: 0.8rem;
  color: #333;
}

@media screen and (max-width: 450px) {
  .user__details_section {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }
}
.avatar__section_card {
  display: block;
  position: relative;
  width: 120px;
  left: 15%;
  /* top: 50px; */
  transform: translate(-50%);
  text-align: center;
}
.avatar__section_image {
  background: linear-gradient(60deg, #aa1571, #ff9966);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 6px solid #f0efef;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:hover.avatar__section_image {
  transform: scale(1.1);
}

// license detail card
.user_details_card2::after,
.user_details_card2 {
  border-radius: 50%;
}
.user_details_card2 {
  display: flex;
}

.user_details_card2 {
  padding: 1.5rem 1rem;
  border-radius: 10px;
  //   background-color: rgba(255, 42, 42, 0.5);
  min-width: 339px;
  // max-width: 409px;
  height: 186px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.user_details_card2::before,
.user_details_card2::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.user_details_card2::before {
  width: 100%;
  height: 100%;
  border: 1px solid #e9e7e7;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}
.user_details_card2::after {
  height: 15rem;
  width: 15rem;
  background-color: #c81d1d;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #fff;
}
.card__mobile_image{
  display: none;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  object-fit: cover;
  transform: translate(-50%,-50%);
  z-index: -1;
  height: 100%;
  box-sizing: border-box;

}
@media only screen and (max-width: 728px) {
    .card__mobile_image{
      display: block;
      
    }
    
}
