.node-card-v2 {
  background-color: #393939;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;

  &--short {
    min-height: 27rem;
  }

  &--long {
    min-height: 52rem;
  }

  &__header {
    min-height: 12rem;
    background-color: #d54639;

    &__1 {
      color: #dcdcdc;
      font-weight: bold;

      & .icons-section {
        display: flex;
        list-style: none;
        float: right;
        padding-right: 20px;
        vertical-align: top;
        margin-top: 10px;
      }
    }
  }

  &__avatar {
    position: absolute;
    width: 12rem;
    left: 1.4rem;
    top: 4.5rem;
    // transform: translate(-50%);
    text-align: center;
    transition: transform 0.2s;
    & p:nth-of-type(1) {
      text-transform: uppercase;
      font-weight: 900;
    }
    &:hover {
      transform: scale(1.08);
    }

    &__image {
      background: linear-gradient(60deg, #aa1571, #ff9966);
      box-sizing: border-box;
      border-radius: 50%;
      border: 6px solid #393939;
      height: 11rem;
      width: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__header-content {
    position: absolute;
    display: block;
    left: 14rem;

    & p {
      color: #eee;
    }
  }

  &__content {
    height: 57.66%;
    margin-top: 2.5rem;
  }
}
