.header {
  font-size: 1.4rem;
  height: 5rem;
  background-color: $color-primary;
  // border-bottom: $line;
  display: flex;
  color: white;
  align-items: center;

  &__logo {
    flex: 0 0 20rem;
    cursor: pointer;
  }

  &__burger {
    align-self: center;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    padding: 0.8rem 1rem;
    margin-left: 1px;
    background-color: $color-primary-light;
    margin-right: auto;
    fill: currentColor;

    &__input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }
  }

  &__tray {
    margin: 0.5rem;
  }
}
