.node-modal {
  min-height: 50rem;
}

.node-modal-card {
  &__footer {
    padding: 1rem 2rem;
  }
}

.node-modal-container {
  padding: 1rem;
  background-color: $white;
}

.node-modal__parameter-carousel {
  min-height: 10rem;
}

.node-modal-card-content {
  //   background-color: lightgray;
  .node-info {
    &__icon {
      height: 2rem;
      width: 2rem;
    }
    &__name {
      padding: 0 2rem 0 2rem;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      font-size: 1.2rem;
    }
  }
  .node-info-content {
    // margin: 2rem;
    text-align: center;

    &__circle {
      // margin: 1.5rem;
      background-color: lightgrey;
      display: inline-block;
      border-radius: 50%;
      width: 16.8rem;
      height: 16.8rem;
      display: inline-block;
      &__value {
        margin-top: 5rem;

        font-weight: bold;
      }
      &__name {
        margin-top: 2rem;
      }
      &__msg {
        margin-top: 2rem;
      }
    }
    &__rect {
      margin: 1.5rem;
      background-color: white;
      display: inline-block;
      text-align: center;
      height: 16.8rem;
      width: 24rem;
      &__value {
        margin: 4rem;
        font-weight: bold;
      }
      &__name {
        margin-top: -4rem;
      }
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 21rem;
}

.carousel {
  position: relative;
  transition: 1s;
  left: 0;
}

// Carousel slides
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  max-width: 90rem;
  list-style-type: none;
  text-align: center;

  &--active {
    display: block;
  }
}

// Carousel arrows
.carousel__arrow {
  border: solid $black;
  border-width: 0 0.4rem 0.4rem 0;
  padding: 0.2rem;
  height: 1rem;
  width: 1rem;

  position: absolute;
  top: 50%;
  display: block;
  cursor: pointer;
  opacity: 0.75;
  transform: translateY(-50%);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  &--left {
    left: 3.2rem;
    transform: rotate(135deg);
  }

  &--right {
    right: 3.2rem;
    transform: rotate(-45deg);
  }
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 0.9rem;
    }
  }
}

.carousel__indicator {
  margin-bottom: 1rem;
  display: block;
  width: 3.4rem;
  height: 0.3rem;
  background-color: #111;
  cursor: pointer;
  opacity: 0.15;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  text-align: center;
  &:hover {
    opacity: 0.5;
  }

  &--active {
    &,
    &:hover {
      opacity: 0.75;
    }
  }
}
