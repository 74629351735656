.sidebar {
  flex: 0 0 20rem;
  padding-top: 3rem;
  padding-left: 1rem;
  border-right: $line;
  background-color: $color-grey-dark-1;
  -webkit-transition: all 5s ease;
  -moz-transition: all 5s ease;
  transition: all 5s ease;
}

.sidebar-hidden {
  display: none;
  -webkit-transition: all 5s ease;
  -moz-transition: all 5s ease;
  transition: all 5s ease;
}
.menu__items {
  display: flex;
}
