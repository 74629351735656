.legends {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
  border: 1px solid #a0a0a0;

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }

  &--no-border {
    border: none;
  }
}
