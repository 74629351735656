.content-container {
  background-color: $color-grey-light-3;
  flex: 1;
  min-height: 91vh;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 1rem;
    background-color: $color-grey-light-3;
    color: $text-color-dark;
  }
}
