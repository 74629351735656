*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

html {
  font-size: 62.5%;
  @include respond("mobile") {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
}
